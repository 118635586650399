.text-message-container {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 15px;
  max-width: 400px;
  max-height: 350px;
  overflow-y: auto;
  margin: auto;
}

.section {
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}

.section span {
  display: block;
}

.link-item {
  margin: 5px 0;
  padding: 5px;
}

.link-item:hover {
  background-color: white;
}

.remove-icon {
  color: lightcoral; /* Lighter red color when row is hovered */

  display: none;
  cursor: pointer;
}

.link-item .remove-icon {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in-out;
}

.link-item:hover .remove-icon {
  visibility: visible;
  opacity: 1;
  color: lightcoral; /* Lighter red color when row is hovered */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.link-item .remove-icon:hover {
  color: red; /* Brighter red when icon itself is hovered */
}

.custom-pre {
  font-family: inherit;
  white-space: pre-wrap; /* Allows the text to wrap */
  word-wrap: break-word; /* Ensures long words do not break the layout */
  font-size: inherit;
  line-height: 1.6; /* Adjust line height for better readability */
  /* Add any other styles you need, such as color, margins, etc. */
}

.grayed-out-links {
  opacity: 0.5; /* Grayed out effect */
  position: relative;
}

.grayed-out-link-item {
  margin: 5px 0;
  padding: 5px;
  cursor: default;
}

.lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
